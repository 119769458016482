<template>
  <div>
    <div class="title">Notice List</div>
    <el-table
      :data="tableData"
      stripe
      style="width: 100%; cursor: pointer"
      @row-click="getDetails"
    >
      <el-table-column prop="title" label="Title">
        <template slot-scope="scope">
          <i v-if="scope.row.is_read === 1" class="iconfont icon-yiduxinxi"></i>
          <i v-if="scope.row.is_read === 0" class="iconfont icon-youxiang"></i>
          {{ scope.row.title }}
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="Date" width="300">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { noticelist } from '../../api/user'
export default {
  data() {
    return {
      tableData: [],
      detail: {}
    }
  },
  created() {
    this.getlist()
  },
  methods: {
    async getlist() {
      const data = await noticelist()
      if (data.length > 0) {
        this.tableData = data
      }
    },
    getDetails(row) {
      this.$router.push({
        path: '/noticedetail',
        query: { id: row.id }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  font-size: 30px;
  font-weight: bold;
  padding: 20px 0;
  color: #333;
}
/deep/.el-table tr th {
  background-color: #b3d8ff;
  color: #fff;
}
.icon-youxiang {
  color: #e6a23c;
}
</style>
